"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_TIME_ELAPSED = exports.SET_TIMER_ACTIVE = exports.RESET_TIMER_STATE = void 0;
var RESET_TIMER_STATE = "RESET_TIMER_STATE";
exports.RESET_TIMER_STATE = RESET_TIMER_STATE;
var SET_TIMER_ACTIVE = "SET_TIMER_ACTIVE";
exports.SET_TIMER_ACTIVE = SET_TIMER_ACTIVE;
var SET_TIME_ELAPSED = "SET_TIME_ELAPSED";
exports.SET_TIME_ELAPSED = SET_TIME_ELAPSED;