"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBoardDispatches = void 0;

var types = _interopRequireWildcard(require("../action_types"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

var useBoardDispatches = function useBoardDispatches(dispatch) {
  var resetState = function resetState() {
    return dispatch({
      type: types.RESET_STATE
    });
  };

  var setLoading = function setLoading(isLoading) {
    return dispatch({
      type: types.SET_LOADING,
      payload: {
        isLoading: isLoading
      }
    });
  };

  var setBoard = function setBoard(board) {
    return dispatch({
      type: types.SET_BOARD,
      payload: {
        board: board
      }
    });
  };

  var setInitialized = function setInitialized() {
    var isInitialized = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    return dispatch({
      type: types.SET_INITIALIZED,
      payload: {
        isInitialized: isInitialized
      }
    });
  };

  var setDifficulty = function setDifficulty(difficulty) {
    return dispatch({
      type: types.SET_DIFFICULTY,
      payload: {
        difficulty: difficulty
      }
    });
  };

  var setCurrentCell = function setCurrentCell(currentCell) {
    return dispatch({
      type: types.SET_CURRENT_CELL,
      payload: {
        currentCell: currentCell
      }
    });
  };

  var setWriteCandidates = function setWriteCandidates(writeCandidates) {
    return dispatch({
      type: types.SET_WRITE_CANDIDATES,
      payload: {
        writeCandidates: writeCandidates
      }
    });
  };

  var setCandidates = function setCandidates(candidates) {
    return dispatch({
      type: types.SET_CANDIDATES,
      payload: {
        candidates: candidates
      }
    });
  };

  var setIsSolved = function setIsSolved(isSolved) {
    return dispatch({
      type: types.SET_IS_SOLVED,
      payload: {
        isSolved: isSolved
      }
    });
  };

  return {
    resetState: resetState,
    setLoading: setLoading,
    setBoard: setBoard,
    setInitialized: setInitialized,
    setDifficulty: setDifficulty,
    setCurrentCell: setCurrentCell,
    setWriteCandidates: setWriteCandidates,
    setCandidates: setCandidates,
    setIsSolved: setIsSolved
  };
};

exports.useBoardDispatches = useBoardDispatches;