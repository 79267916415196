import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ListAlt } from '@material-ui/icons';
import { ListSubheader } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import sudokuimg from './img/sudoku.png';
import springimg from './img/spring.gif';
import myImage from './img/shop2.jpeg';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function TemporaryDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >

            <List>
                <ListSubheader>Elementary school learning tools.</ListSubheader>
                <ListItem button component="a" href="http://www.hightechguru.net/numbers/worksheet_home" target="_blank">
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Print maths worksheets" />
                </ListItem>

                <ListItem button component="a" href="http://hightechguru.net/numbers/live_math" target="_blank">
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Maths practice test" />
                </ListItem>

                <ListItem button component="a" href="http://hightechguru.net/numbers/" target="_blank">
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="More learning tools" />
                </ListItem>

            </List>
            <Divider />
            <List>
                <ListSubheader>Free alexa skils.</ListSubheader>
                <ListItem button component="a" href="www.amazon.com/dp/B078QTMMLR/ref=as_sl_pc_as_ss_li_til?tag=guru0400-20&linkCode=w00&linkId=5cf7a0d7085d24c95fc4522731acc44b&creativeASIN=B078QTMMLR" target="_blank">
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Numbers guru" />
                </ListItem>
                <ListItem button component="a" href="www.amazon.com/Personal-Leadership-Principles/dp/B07V8M3VS4/ref=cm_cr_arp_d_pdt_img_top?ie=UTF8" target="_blank">
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Amazon Leadership Principles" />
                </ListItem>
            </List>

            <Divider />
            <List>
                <ListSubheader>Machine learning tools</ListSubheader>
                <ListItem button component="a" href="http://hightechguru.net/tools/image_search" target="_blank">
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Search image." />
                </ListItem>

                <ListItem button component="a" href="http://hightechguru.net/rekognition/ai_home" target="_blank">
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="More AI Tools" />
                </ListItem>

            </List>

        </div>
    );

    return (
        <div className="container">
          <div className="header">
            <h2 style={{ color: 'blue' }}>Welcome to Guru's Workshop</h2>
          </div>
          <div className="button-container">
            <button className="button">
              <a href="https://gurusworkshop.etsy.com" target="_blank" rel="noopener noreferrer">
                Shop our products on Etsy!
              </a>
            </button>
          </div>
          <br></br>
          <div>
          <img src={myImage} alt="My Image" />
          </div>

        </div>
      );
}
