"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _StoreContext = require("../../hooks/StoreContext");

require("./difficulty-buttons-styles.css");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

var DifficultyButtons = function DifficultyButtons(props) {
  var disable = props.disable,
      defaultDifficulty = props.defaultDifficulty,
      size = props.size;

  var _useContext = (0, _react.useContext)(_StoreContext.StoreContext),
      state = _useContext.state,
      actions = _useContext.actions;

  var _state$board = state.board,
      difficulty = _state$board.difficulty,
      board = _state$board.board;
  var generateSudoku = actions.generateSudoku;

  if (defaultDifficulty) {
    if (board.isEmpty()) generateSudoku(defaultDifficulty);
  }

  if (disable) {
    if (board.isEmpty()) generateSudoku('easy');
    return _react["default"].createElement(_react["default"].Fragment, null);
  }

  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement("div", {
    className: "button-container",
    style: {
      width: size
    }
  }, _react["default"].createElement(DifficultyButton, {
    difficultyForButton: 'easy',
    onClick: generateSudoku,
    difficulty: difficulty
  }), _react["default"].createElement(DifficultyButton, {
    difficultyForButton: 'medium',
    onClick: generateSudoku,
    difficulty: difficulty
  }), _react["default"].createElement(DifficultyButton, {
    difficultyForButton: 'hard',
    onClick: generateSudoku,
    difficulty: difficulty
  })));
};

var DifficultyButton = function DifficultyButton(props) {
  var difficultyForButton = props.difficultyForButton,
      difficulty = props.difficulty,
      _onClick = props.onClick;
  var activeStyle = 'difficulty-button difficulty-button-active';
  var normalStyle = 'difficulty-button';
  return _react["default"].createElement("button", {
    className: difficultyForButton === difficulty ? activeStyle : normalStyle,
    onClick: function onClick() {
      return _onClick(difficultyForButton);
    }
  }, difficultyForButton);
};

var _default = DifficultyButtons;
exports["default"] = _default;