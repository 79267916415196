"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _board_initial_state = require("../initial_states/board_initial_state");

var types = _interopRequireWildcard(require("../action_types"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var board_reducer = function board_reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _board_initial_state.boardInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.RESET_STATE:
      return _objectSpread({}, _board_initial_state.boardInitialState);

    case types.SET_LOADING:
      // TODO set some loading variable to true
      return _objectSpread({}, state, {
        isLoading: action.payload.isLoading
      });

    case types.SET_BOARD:
      return _objectSpread({}, state, {
        board: action.payload.board
      });

    case types.SET_INITIALIZED:
      return _objectSpread({}, state, {
        isInitialized: action.payload.isInitialized
      });

    case types.SET_DIFFICULTY:
      return _objectSpread({}, state, {
        difficulty: action.payload.difficulty
      });

    case types.SET_CURRENT_CELL:
      return _objectSpread({}, state, {
        currentCell: action.payload.currentCell
      });

    case types.SET_WRITE_CANDIDATES:
      return _objectSpread({}, state, {
        writeCandidates: action.payload.writeCandidates
      });

    case types.SET_CANDIDATES:
      return _objectSpread({}, state, {
        candidates: action.payload.candidates
      });

    case types.SET_IS_SOLVED:
      return _objectSpread({}, state, {
        isSolved: action.payload.isSolved
      });

    default:
      return state;
  }
};

var _default = board_reducer;
exports["default"] = _default;