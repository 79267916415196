"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHelpDispatches = void 0;

var types = _interopRequireWildcard(require("../action_types"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

var useHelpDispatches = function useHelpDispatches(dispatch) {
  var resetHelpState = function resetHelpState() {
    return dispatch({
      type: types.RESET_HELP_STATE
    });
  };

  var setSolution = function setSolution(solution) {
    return dispatch({
      type: types.SET_SOLUTION,
      payload: {
        solution: solution
      }
    });
  };

  var setInvalidCells = function setInvalidCells(invalidCells) {
    return dispatch({
      type: types.SET_INVALID_CELLS,
      payload: {
        invalidCells: invalidCells
      }
    });
  };

  var setShowHelp = function setShowHelp(showHelp) {
    return dispatch({
      type: types.SET_SHOW_HELP,
      payload: {
        showHelp: showHelp
      }
    });
  };

  var setHelpUsage = function setHelpUsage(helpUsage) {
    return dispatch({
      type: types.SET_HELP_USAGE,
      payload: {
        helpUsage: helpUsage
      }
    });
  };

  var setOnTheGoValidation = function setOnTheGoValidation(onTheGoValidation) {
    return dispatch({
      type: types.SET_ON_THE_GO_VALIDATION,
      payload: {
        onTheGoValidation: onTheGoValidation
      }
    });
  };

  var setShowConnectedCells = function setShowConnectedCells(showConnectedCells) {
    return dispatch({
      type: types.SET_SHOW_CONNECTED_CELLS,
      payload: {
        showConnectedCells: showConnectedCells
      }
    });
  };

  var setPlaceAllOfActive = function setPlaceAllOfActive(placeAllOfActive) {
    return dispatch({
      type: types.SET_PLACE_ALL_OF_ACTIVE,
      payload: {
        placeAllOfActive: placeAllOfActive
      }
    });
  };

  var setHintedCells = function setHintedCells(hintedCells) {
    return dispatch({
      type: types.SET_HINTED_CELLS,
      payload: {
        hintedCells: hintedCells
      }
    });
  };

  return {
    resetHelpState: resetHelpState,
    setSolution: setSolution,
    setInvalidCells: setInvalidCells,
    setShowHelp: setShowHelp,
    setHelpUsage: setHelpUsage,
    setOnTheGoValidation: setOnTheGoValidation,
    setShowConnectedCells: setShowConnectedCells,
    setPlaceAllOfActive: setPlaceAllOfActive,
    setHintedCells: setHintedCells
  };
};

exports.useHelpDispatches = useHelpDispatches;