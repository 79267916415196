import React, { Component } from 'react';
import Sudoku from './react-sudoku-component/dist';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import myImage from './img/workshop.jpeg';


class Puzzle extends Component {
    render() {
        return (

            <div className="navbar-brand-custom">
                <div className="container">
                    <div className="header">
                        <h2 style={{ color: 'blue' }}>Welcome to Guru's Workshop</h2>
                    </div>
                    <div className="button-container">
                        <button className="button">
                            <a href="https://gurusworkshop.etsy.com" target="_blank" rel="noopener noreferrer">
                                Shop our products on Etsy!
                            </a>
                        </button>
                    </div>
                    <br></br>

                </div>
                <br />

                <h4>Unlimited Sudoku Puzzles!</h4><br />
                <Sudoku /><br />
                <Button variant="primary" onClick={() => window.print()}>PRINT</Button><br /><br /><br /><br /><br /><br />
                

            </div>
        )
    }
}

export default Puzzle;