"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _StoreContext = require("../../hooks/StoreContext");

require("./help-buttons-styles.css");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

var HelpButtons = function HelpButtons(props) {
  var size = props.size,
      disable = props.disable,
      allowedHelps = props.allowedHelps,
      disabledHelps = props.disabledHelps;

  var _useContext = (0, _react.useContext)(_StoreContext.StoreContext),
      state = _useContext.state,
      actions = _useContext.actions;

  var _state$help = state.help,
      showHelp = _state$help.showHelp,
      onTheGoValidation = _state$help.onTheGoValidation,
      showConnectedCells = _state$help.showConnectedCells,
      placeAllOfActive = _state$help.placeAllOfActive;
  var validateSudoku = actions.validateSudoku,
      clearBoard = actions.clearBoard,
      solveSudoku = actions.solveSudoku,
      addHint = actions.addHint,
      toggleShowHelp = actions.toggleShowHelp,
      toggleOnTheGoValidation = actions.toggleOnTheGoValidation,
      toggleShowConnectedCells = actions.toggleShowConnectedCells,
      togglePlaceAllOfActive = actions.togglePlaceAllOfActive;
  var helpButtons = [{
    key: 'solve',
    button: _react["default"].createElement(HelpButton, {
      key: 'solve',
      onClick: solveSudoku
    }, "Solve")
  }, {
    key: 'validate',
    button: _react["default"].createElement(HelpButton, {
      key: 'validate',
      onClick: function onClick() {
        return validateSudoku();
      }
    }, "Validate")
  }, {
    key: 'hint',
    button: _react["default"].createElement(HelpButton, {
      key: 'hint',
      onClick: function onClick() {
        return addHint();
      }
    }, "Hint")
  }, {
    key: 'hintAllOf',
    button: _react["default"].createElement(HelpButton, {
      key: 'hintAllOf',
      onClick: togglePlaceAllOfActive,
      isActive: placeAllOfActive
    }, "Hint all of #")
  }, {
    key: 'validateOnTheGo',
    button: _react["default"].createElement(HelpButton, {
      key: 'validateOnTheGo',
      onClick: toggleOnTheGoValidation,
      isActive: onTheGoValidation
    }, "Validate OnTheGo")
  }];
  var slideDown = "help-button-container open";
  var normalClass = "help-button-container";
  if (state.board.isInitialized) return _react["default"].createElement("div", {
    className: "show-help-container"
  }, _react["default"].createElement(HelpButton, {
    onClick: clearBoard
  }, "Clear"), _react["default"].createElement(HelpButton, {
    onClick: toggleShowConnectedCells,
    isActive: showConnectedCells
  }, "Show connected cells"), _react["default"].createElement("br", null), !disable ? _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement("button", {
    className: "show-help-button",
    onClick: toggleShowHelp
  }, showHelp ? 'Hide Help' : 'Show Help'), _react["default"].createElement("div", {
    className: showHelp ? slideDown : normalClass,
    style: showHelp ? {
      visibility: 'visible',
      width: size
    } : {
      visibility: 'hidden',
      width: size
    }
  }, helpButtons.map(function (helpButton) {
    return disabledHelps ? disabledHelps.includes(helpButton.key) ? null : helpButton.button : allowedHelps.includes(helpButton.key) ? helpButton.button : null;
  }))) : null);else return _react["default"].createElement(_react["default"].Fragment, null);
};

var HelpButton = function HelpButton(props) {
  var onClick = props.onClick,
      isActive = props.isActive,
      children = props.children;
  return _react["default"].createElement("button", {
    className: "help-button",
    onClick: onClick,
    style: isActive ? {
      background: 'red'
    } : null
  }, children);
};

var _default = HelpButtons;
exports["default"] = _default;