"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "boardInitialState", {
  enumerable: true,
  get: function get() {
    return _board_initial_state.boardInitialState;
  }
});
Object.defineProperty(exports, "timerInitialState", {
  enumerable: true,
  get: function get() {
    return _timer_initial_state.timerInitialState;
  }
});
Object.defineProperty(exports, "helpInitialState", {
  enumerable: true,
  get: function get() {
    return _help_initial_state.helpInitialState;
  }
});

var _board_initial_state = require("./board_initial_state");

var _timer_initial_state = require("./timer_initial_state");

var _help_initial_state = require("./help_initial_state");