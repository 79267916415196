"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.boardInitialState = void 0;

var _sudoku = _interopRequireDefault(require("../../models/sudoku"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var boardInitialState = {
  board: new _sudoku["default"](),
  isLoading: false,
  isInitialized: false,
  difficulty: '',
  currentCell: 0,
  writeCandidates: false,
  candidates: new Map(),
  isSolved: false,
  onSolved: function onSolved() {}
};
exports.boardInitialState = boardInitialState;