"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _StoreContext = require("../../hooks/StoreContext");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

var DIRECTIONAL_KEYS = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40
};
var DELETING_KEYS = {
  BACKSPACE: 8,
  DEL: 46,
  KEYBOARD_0: 48,
  KEYPAD_0: 96
};
var NUMERIC_KEYS = {
  KEYBOARD_1: 49,
  KEYBOARD_2: 50,
  KEYBOARD_3: 51,
  KEYBOARD_4: 52,
  KEYBOARD_5: 53,
  KEYBOARD_6: 54,
  KEYBOARD_7: 55,
  KEYBOARD_8: 56,
  KEYBOARD_9: 57,
  KEYPAD_1: 97,
  KEYPAD_2: 98,
  KEYPAD_3: 99,
  KEYPAD_4: 100,
  KEYPAD_5: 101,
  KEYPAD_6: 102,
  KEYPAD_7: 103,
  KEYPAD_8: 104,
  KEYPAD_9: 105
};
var FUNCTIONAL_KEYS = {
  M: 77
};

var KeyboardEventListener = function KeyboardEventListener(props) {
  var _useContext = (0, _react.useContext)(_StoreContext.StoreContext),
      state = _useContext.state,
      actions = _useContext.actions;

  if (props.active) {
    (0, _react.useEffect)(function () {
      window.addEventListener('keydown', handleKeyDown);
      return function cleanup() {
        window.removeEventListener('keydown', handleKeyDown);
      };
    });
  }

  var handleKeyDown = function handleKeyDown(event) {
    var keyCode = event.keyCode;

    if (keyExists(keyCode)) {
      event.preventDefault();
      if (keyIsNumeric(keyCode)) handleNumericKey(parseInt(event.key));else if (keyIsDirectional(keyCode)) handleDirectionalKey(keyCode);else if (keyIsDeleting(keyCode)) handleDeletingKey();else if (keyIsFunctional(keyCode)) handleFunctionalKey(keyCode);
    }
  };

  var keyExists = function keyExists(keyCode) {
    return keyIsNumeric(keyCode) || keyIsDirectional(keyCode) || keyIsDeleting(keyCode) || keyIsFunctional(keyCode);
  };

  var keyIsNumeric = function keyIsNumeric(keyCode) {
    return Object.values(NUMERIC_KEYS).includes(keyCode);
  };

  var keyIsDirectional = function keyIsDirectional(keyCode) {
    return Object.values(DIRECTIONAL_KEYS).includes(keyCode);
  };

  var keyIsDeleting = function keyIsDeleting(keyCode) {
    return Object.values(DELETING_KEYS).includes(keyCode);
  };

  var keyIsFunctional = function keyIsFunctional(keyCode) {
    return Object.values(FUNCTIONAL_KEYS).includes(keyCode);
  };

  var handleNumericKey = function handleNumericKey(number) {
    return actions.handleNumberClick(number);
  };

  var directionValues = {
    37: -1,
    39: 1,
    38: -9,
    40: 9
  };

  var handleDirectionalKey = function handleDirectionalKey(directionKeyCode) {
    var newCell = state.board.currentCell + directionValues[directionKeyCode];
    if (newCell < 0) newCell += 81;
    actions.setCurrentCell(newCell % 81);
  };

  var handleDeletingKey = function handleDeletingKey() {
    return actions.removeNumberFromCell(state.currentCell);
  };

  var handleFunctionalKey = function handleFunctionalKey(keyCode) {
    switch (keyCode) {
      case FUNCTIONAL_KEYS.M:
        actions.toggleWriteCandidates();
        break;
    }
  };

  return _react["default"].createElement(_react["default"].Fragment, null, props.children);
};

var _default = KeyboardEventListener;
exports["default"] = _default;