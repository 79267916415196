"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _StoreContext = require("../../hooks/StoreContext");

require("./number-buttons-styles.css");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

var NumberButtons = function NumberButtons(props) {
  var disable = props.disable,
      size = props.size;
  if (disable) return _react["default"].createElement(_react["default"].Fragment, null);

  var _useContext = (0, _react.useContext)(_StoreContext.StoreContext),
      state = _useContext.state,
      actions = _useContext.actions;

  var writeCandidates = state.board.writeCandidates;
  var toggleWriteCandidates = actions.toggleWriteCandidates,
      handleNumberClick = actions.handleNumberClick;
  var buttons = [];

  var _loop = function _loop(i) {
    buttons.push(_react["default"].createElement("button", {
      key: i,
      className: "number-button",
      onClick: function onClick() {
        return handleNumberClick(i);
      }
    }, i !== 0 ? i : 'C'));
  };

  for (var i = 0; i < 10; ++i) {
    _loop(i);
  }

  buttons.push(_react["default"].createElement("button", {
    key: 'help',
    className: "number-button",
    onClick: toggleWriteCandidates,
    style: writeCandidates ? {
      border: '2px inset gray',
      background: 'lightgray'
    } : {
      border: '2px outset gray'
    }
  }, "M"));
  if (state.board.isInitialized) return _react["default"].createElement("div", {
    className: "number-container",
    style: {
      width: size,
      height: "calc(".concat(size, "/11)")
    }
  }, buttons);else return _react["default"].createElement(_react["default"].Fragment, null);
};

var _default = NumberButtons;
exports["default"] = _default;