"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSudokuFromSolvedSudoku = exports.difficulties = exports.generateSudokuRow = exports.generateSudoku = exports.recSolve = exports.solve = exports.getConnectedCells = exports.validateCell = exports.validate_sudoku = void 0;

var _sudoku = _interopRequireDefault(require("../models/sudoku"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Validates a sudoku-board and return the invalid cells.
 *
 * @param {Sudoku} board the sudoku-board to validate
 * @returns {number[]} an array containing the invalid cells
 */
var validate_sudoku = function validate_sudoku(board) {
  var invalidCells = [];

  for (var i = 0; i < 81; ++i) {
    if (!validateCell(board, i)) invalidCells.push(i);
  }

  return invalidCells;
};
/**
 * Validates a single cell.
 * @param {Sudoku} board the sudoku-board where the cell is included
 * @param {number} cell the cell to validate
 * @returns {boolean} true if the cell is valid, else false
 */


exports.validate_sudoku = validate_sudoku;

var validateCell = function validateCell(board, cell) {
  if (board.get(cell) === 0) return true;
  var nbr = board.get(cell);
  var connectedCells = getConnectedCells(cell);
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = connectedCells[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var j = _step.value;

      if (board.get(j) === nbr) {
        return false;
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator["return"] != null) {
        _iterator["return"]();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return true;
};
/**
 * Returns an array containing
 * all cells connected to the current cell.
 * (row, column and cell-group)
 * @param {number} cell the cell from which to find connected cells
 * @returns {number[]} an array containing all cells connected to the specified cell
 */


exports.validateCell = validateCell;

var getConnectedCells = function getConnectedCells(cell) {
  var connectedCells = [];
  var rowStart = cell;

  while (rowStart % 9 !== 0) {
    rowStart--;
  }

  var colStart = cell;

  while (colStart > 8) {
    colStart -= 9;
  }

  var cellGroupStart = cell;

  while (cellGroupStart % 27 > 8) {
    cellGroupStart -= 9;
  }

  while (cellGroupStart % 3 !== 0) {
    cellGroupStart--;
  }

  for (var i = 0; i < 9; ++i) {
    var actRow = rowStart + i;
    var actCol = colStart + 9 * i;
    var actCellGroup = cellGroupStart + i % 3;
    if (actRow !== cell && !connectedCells.includes(actRow)) connectedCells.push(actRow);
    if (actCol !== cell && !connectedCells.includes(actCol)) connectedCells.push(actCol);
    if (actCellGroup !== cell && !connectedCells.includes(actCellGroup)) connectedCells.push(actCellGroup);

    if ((actCellGroup + 1) % 3 === 0) {
      cellGroupStart += 9;
    }
  }

  return connectedCells;
};
/**
 * Solves the sudoku that is passed. The solution will be written on the passed board.
 * @param {Sudoku} board the board to solve
 * @returns {boolean} true if the sudoku was solvable, else false
 */


exports.getConnectedCells = getConnectedCells;

var solve = function solve(board) {
  if (validate_sudoku(board).length === 0) return recSolve(0, board, board);else return false;
};
/**
 * Recursive funtion that solved a sudoku by brute force
 * @param {number} cell number of the current cell (0-81)
 * @param {Sudoku} board the board to solve
 * @param {Sudoku} initialBoard the board as it looked in the beginning
 *
 * @returns true if the sudoku was solved, else false
 */


exports.solve = solve;

var recSolve = function recSolve(cell, board, initialBoard) {
  if (cell === 81) {
    return validate_sudoku(board).length === 0;
  }

  if (validate_sudoku(board).length === 0) {
    if (initialBoard.get(cell) !== 0) {
      return recSolve(cell + 1, board, initialBoard);
    }

    for (var i = 1; i < 10; ++i) {
      board.set(cell, i);

      if (recSolve(cell + 1, board, initialBoard)) {
        return true;
      }

      board.set(cell, 0);
    }
  }

  return false;
};
/**
 * Generates a random sudoku
 * @param {('easy'|'medium'|'hard')} difficulty the difficulty of the sudoku to solve
 *
 * @returns {{sudoku: Sudoku, solution: Sudoku}} the generated sudoku and its solution
 */


exports.recSolve = recSolve;

var generateSudoku = function generateSudoku(difficulty) {
  var solved;
  var solvedSudoku;

  do {
    var firstRow = generateSudokuRow();
    var lastRow = generateSudokuRow([firstRow]);
    solvedSudoku = new _sudoku["default"]();

    for (var i = 0; i < 9; ++i) {
      solvedSudoku.set(i, firstRow[i]);
      solvedSudoku.set(i + 72, lastRow[i]);
    }

    solved = solve(solvedSudoku);
  } while (solved !== true);

  var sudoku = createSudokuFromSolvedSudoku(solvedSudoku, difficulty);
  return {
    sudoku: sudoku,
    solution: solvedSudoku
  };
};
/**
 * Generates a row for a sudoku.
 * @param {number[][]} matchingRows an array of arrays containing numbers from neighbor rows
 *
 * @returns {number[]} an array of numbers to be placed in a row
 */


exports.generateSudoku = generateSudoku;

var generateSudokuRow = function generateSudokuRow() {
  var matchingRows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var MAX_NBR_OF_TRIES = 1000;
  var ROW_LENGTH = 9;

  var randomCellNumber = function randomCellNumber() {
    return Math.floor(Math.random() * 9) + 1;
  };

  var row = [];

  while (row.length !== ROW_LENGTH) {
    var nbrOfTries = 0;
    var nbrsInOtherRows = matchingRows.map(function (otherRow) {
      return otherRow[row.length];
    });
    var cellNbr = randomCellNumber();

    while (++nbrOfTries <= MAX_NBR_OF_TRIES && (row.includes(cellNbr) || nbrsInOtherRows.includes(cellNbr))) {
      cellNbr = randomCellNumber();
    }

    if (nbrOfTries >= MAX_NBR_OF_TRIES) row = [];
    row.push(cellNbr);
  }

  return row;
};

exports.generateSudokuRow = generateSudokuRow;
var difficulties = {
  'easy': 37,
  'medium': 29,
  'hard': 21
};
/**
 * Creates a sudoku with the specified difficulty from the passed solved sudoku
 * @param {Sudoku} solvedSudoku a solved sudoku
 * @param {('easy'|'medium'|'hard')} difficulty difficulty of the new sudoku
 *
 * @returns {Sudoku} a sudoku derived from the solved sudoku.
 */

exports.difficulties = difficulties;

var createSudokuFromSolvedSudoku = function createSudokuFromSolvedSudoku(solvedSudoku, difficulty) {
  var getRandomCellNumber = function getRandomCellNumber() {
    return Math.floor(Math.random() * 81);
  };

  var nbrsToAdd = difficulties[difficulty];
  var sudoku = new _sudoku["default"]();

  for (var i = 0; i < nbrsToAdd; ++i) {
    var cell = getRandomCellNumber();

    while (sudoku.get(cell) !== 0) {
      cell = getRandomCellNumber();
    }

    sudoku.board[cell] = solvedSudoku.get(cell);
  }

  return sudoku;
};

exports.createSudokuFromSolvedSudoku = createSudokuFromSolvedSudoku;