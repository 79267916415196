"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _initial_states = require("./initial_states");

var initialState = {
  board: _initial_states.boardInitialState,
  timer: _initial_states.timerInitialState,
  help: _initial_states.helpInitialState
};
var _default = initialState;
exports["default"] = _default;