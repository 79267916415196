"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Board = void 0;

var _react = _interopRequireWildcard(require("react"));

var _StoreContext = require("../../hooks/StoreContext");

require("./board-styles.css");

var SudokuUtils = _interopRequireWildcard(require("../../utils/sudoku_utils"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

var Board = function Board(props) {
  var _useContext = (0, _react.useContext)(_StoreContext.StoreContext),
      state = _useContext.state,
      actions = _useContext.actions;

  var size = props.size;
  var _state$board = state.board,
      board = _state$board.board,
      currentCell = _state$board.currentCell,
      candidates = _state$board.candidates;
  var _state$help = state.help,
      invalidCells = _state$help.invalidCells,
      hintedCells = _state$help.hintedCells;
  var solveAnimate = [];

  var onCellClicked = function onCellClicked(cell) {
    return actions.setCurrentCell(cell);
  };

  var connectedCells = state.help.showConnectedCells ? SudokuUtils.getConnectedCells(state.board.currentCell) : [];
  var NBR_OF_ROWS = 9;
  var EMPTY_CELL = 0;
  var activeCellStyle = {
    background: '#639fff'
  };
  var connectedCellStyle = {
    background: 'yellow'
  };
  var solveCellStyle = {
    background: 'green'
  };

  var fetchStyleForCell = function fetchStyleForCell(i) {
    if (solveAnimate.includes(i)) return solveCellStyle;else if (currentCell === i) return activeCellStyle;else if (solveAnimate.length === 0 && connectedCells.includes(i)) return connectedCellStyle;
    return null;
  };

  var isCandidates = function isCandidates(cell) {
    return board.get(cell) === EMPTY_CELL && candidates.get(cell);
  };

  var isHinted = function isHinted(cell) {
    return hintedCells.includes(cell);
  };

  var isImmutable = function isImmutable(cell) {
    return board.getImmutableCells().includes(cell);
  };

  var isInvalid = function isInvalid(cell) {
    return invalidCells.includes(cell);
  };

  var isNumber = function isNumber(cell) {
    return board.get(cell) !== EMPTY_CELL;
  };

  var getDataInCell = function getDataInCell(cell) {
    var number = board.get(cell);
    if (isHinted(cell)) return _react["default"].createElement("b", {
      style: {
        color: 'blue'
      }
    }, number);else if (isImmutable(cell)) return _react["default"].createElement("b", null, number);else if (isInvalid(cell)) return _react["default"].createElement("b", {
      style: {
        color: 'red'
      }
    }, number);else if (isNumber(cell)) return number;else if (isCandidates(cell)) return _react["default"].createElement(CandidatesGrid, {
      cellCandidates: candidates.get(cell)
    });else return '';
  };

  if (state.board.isInitialized) return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(GridTable, {
    size: size,
    tableClass: "sudoku-board",
    rowClass: "sudoku-row",
    cellClass: "sudoku-cell",
    nbrOfRows: NBR_OF_ROWS,
    onCellClicked: onCellClicked,
    cellStyle: fetchStyleForCell,
    dataParser: getDataInCell
  }));else return _react["default"].createElement(_react["default"].Fragment, null);
};

exports.Board = Board;

var CandidatesGrid = function CandidatesGrid(props) {
  var cellCandidates = props.cellCandidates;

  var dataParser = function dataParser(cell) {
    return cellCandidates.includes(cell + 1) ? cell + 1 : ' ';
  };

  return _react["default"].createElement(GridTable, {
    tableClass: "candidates-grid",
    nbrOfRows: 3,
    dataParser: dataParser
  });
};

var GridTable = function GridTable(props) {
  var size = props.size,
      tableClass = props.tableClass,
      rowClass = props.rowClass,
      cellClass = props.cellClass,
      nbrOfRows = props.nbrOfRows,
      onCellClicked = props.onCellClicked,
      cellStyle = props.cellStyle,
      dataParser = props.dataParser;

  var renderCellsInRow = function renderCellsInRow(row) {
    var cells = [];
    var firstCellOfRow = row * nbrOfRows;
    var lastCellOfRow = firstCellOfRow + nbrOfRows;

    var _loop = function _loop(cell) {
      cells.push(_react["default"].createElement("td", {
        className: cellClass,
        key: cell,
        style: cellStyle ? cellStyle(cell) : null,
        onClick: onCellClicked ? function () {
          return onCellClicked(cell);
        } : null
      }, dataParser(cell)));
    };

    for (var cell = firstCellOfRow; cell < lastCellOfRow; ++cell) {
      _loop(cell);
    }

    return _react["default"].createElement("tr", {
      key: row,
      className: rowClass
    }, cells);
  };

  var renderRows = function renderRows() {
    var rows = [];

    for (var row = 0; row < nbrOfRows; ++row) {
      rows.push(renderCellsInRow(row));
    }

    return _react["default"].createElement("tbody", null, rows);
  };

  return _react["default"].createElement("table", {
    className: tableClass,
    style: {
      height: size,
      width: size
    }
  }, renderRows());
};

var _default = Board;
exports["default"] = _default;