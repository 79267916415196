"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTimerDispatches = void 0;

var types = _interopRequireWildcard(require("../action_types"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

var useTimerDispatches = function useTimerDispatches(dispatch) {
  var resetTimerState = function resetTimerState() {
    return dispatch({
      type: types.RESET_TIMER_STATE
    });
  };

  var setTimerActive = function setTimerActive(timerActive) {
    return dispatch({
      type: types.SET_TIMER_ACTIVE,
      payload: {
        timerActive: timerActive
      }
    });
  };

  var setTimeElapsed = function setTimeElapsed(timeElapsed) {
    return dispatch({
      type: types.SET_TIME_ELAPSED,
      payload: {
        timeElapsed: timeElapsed
      }
    });
  };

  return {
    resetTimerState: resetTimerState,
    setTimerActive: setTimerActive,
    setTimeElapsed: setTimeElapsed
  };
};

exports.useTimerDispatches = useTimerDispatches;