"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_HINTED_CELLS = exports.SET_PLACE_ALL_OF_ACTIVE = exports.SET_SHOW_CONNECTED_CELLS = exports.SET_ON_THE_GO_VALIDATION = exports.SET_HELP_USAGE = exports.SET_SHOW_HELP = exports.SET_INVALID_CELLS = exports.SET_SOLUTION = exports.RESET_HELP_STATE = void 0;
var RESET_HELP_STATE = "RESET_HELP_STATE";
exports.RESET_HELP_STATE = RESET_HELP_STATE;
var SET_SOLUTION = "SET_SOLUTION";
exports.SET_SOLUTION = SET_SOLUTION;
var SET_INVALID_CELLS = "SET_INVALID_CELLS";
exports.SET_INVALID_CELLS = SET_INVALID_CELLS;
var SET_SHOW_HELP = "SET_SHOW_HELP";
exports.SET_SHOW_HELP = SET_SHOW_HELP;
var SET_HELP_USAGE = "SET_HELP_USAGE";
exports.SET_HELP_USAGE = SET_HELP_USAGE;
var SET_ON_THE_GO_VALIDATION = "SET_ON_THE_GO_VALIDATION";
exports.SET_ON_THE_GO_VALIDATION = SET_ON_THE_GO_VALIDATION;
var SET_SHOW_CONNECTED_CELLS = "SET_SHOW_CONNECTED_CELLS";
exports.SET_SHOW_CONNECTED_CELLS = SET_SHOW_CONNECTED_CELLS;
var SET_PLACE_ALL_OF_ACTIVE = "SET_PLACE_ALL_OF_ACTIVE";
exports.SET_PLACE_ALL_OF_ACTIVE = SET_PLACE_ALL_OF_ACTIVE;
var SET_HINTED_CELLS = "SET_HINTED_CELLS";
exports.SET_HINTED_CELLS = SET_HINTED_CELLS;