"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _parts = require("./parts");

var _StoreContext = require("./hooks/StoreContext");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var Sudoku = function Sudoku(props) {
  var useKeyboardListener = props.useKeyboardListener,
      disableTimer = props.disableTimer,
      disableDifficultyButtons = props.disableDifficultyButtons,
      defaultDifficulty = props.defaultDifficulty,
      disableNumberButtons = props.disableNumberButtons,
      disableHelpButtons = props.disableHelpButtons,
      size = props.size,
      allowedHelps = props.allowedHelps,
      onSolved = props.onSolved,
      disabledHelps = props.disabledHelps;
  return _react["default"].createElement(_StoreContext.StoreProvider, {
    style: {
      textAlign: 'center'
    },
    onSolved: onSolved
  }, _react["default"].createElement(_parts.KeyboardEventListener, {
    active: useKeyboardListener
  }, _react["default"].createElement(_parts.Timer, {
    disable: disableTimer
  }), _react["default"].createElement(_parts.DifficultyButtons, {
    disable: disableDifficultyButtons,
    defaultDifficulty: defaultDifficulty,
    size: size
  }), _react["default"].createElement(_parts.Board, {
    size: size
  }), _react["default"].createElement(_parts.NumberButtons, {
    disable: disableNumberButtons,
    size: size
  }), _react["default"].createElement(_parts.HelpButtons, {
    disable: disableHelpButtons,
    size: size,
    allowedHelps: allowedHelps,
    disabledHelps: disabledHelps
  })));
};

Sudoku.defaultProps = {
  useKeyboardListener: true,
  disableTimer: false,
  disableDifficultyButtons: false,
  disableNumberButtons: false,
  disableHelpButtons: false,
  onSolved: function onSolved() {},
  size: '70vmin',
  allowedHelps: ['solve', 'validate', 'hint', 'hintAllOf', 'validateOnTheGo']
};
Sudoku.propTypes = {
  useKeyboardListener: _propTypes["default"].bool,
  size: _propTypes["default"].string,
  defaultDifficulty: _propTypes["default"].oneOf(['easy', 'medium', 'hard']),
  onSolved: _propTypes["default"].func,
  disableTimer: _propTypes["default"].bool,
  disableDifficultyButtons: _propTypes["default"].bool,
  disableNumberButtons: _propTypes["default"].bool,
  disableHelpButtons: _propTypes["default"].bool,
  allowedHelps: _propTypes["default"].array,
  disabledHelps: _propTypes["default"].array
};
var _default = Sudoku;
exports["default"] = _default;