"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _board_action_types = require("./board_action_types");

Object.keys(_board_action_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _board_action_types[key];
    }
  });
});

var _timer_action_types = require("./timer_action_types");

Object.keys(_timer_action_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _timer_action_types[key];
    }
  });
});

var _help_action_types = require("./help_action_types");

Object.keys(_help_action_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _help_action_types[key];
    }
  });
});