"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_IS_SOLVED = exports.SET_CANDIDATES = exports.SET_WRITE_CANDIDATES = exports.SET_CURRENT_CELL = exports.SET_DIFFICULTY = exports.SET_INITIALIZED = exports.SET_BOARD = exports.SET_LOADING = exports.RESET_STATE = void 0;
var RESET_STATE = "RESET_STATE";
exports.RESET_STATE = RESET_STATE;
var SET_LOADING = "SET_LOADING";
exports.SET_LOADING = SET_LOADING;
var SET_BOARD = "SET_BOARD";
exports.SET_BOARD = SET_BOARD;
var SET_INITIALIZED = "SET_INITIALIZED";
exports.SET_INITIALIZED = SET_INITIALIZED;
var SET_DIFFICULTY = "SET_DIFFICULTY";
exports.SET_DIFFICULTY = SET_DIFFICULTY;
var SET_CURRENT_CELL = "SET_CURRENT_CELL";
exports.SET_CURRENT_CELL = SET_CURRENT_CELL;
var SET_WRITE_CANDIDATES = "SET_WRITE_CANDIDATES";
exports.SET_WRITE_CANDIDATES = SET_WRITE_CANDIDATES;
var SET_CANDIDATES = "SET_CANDIDATES";
exports.SET_CANDIDATES = SET_CANDIDATES;
var SET_IS_SOLVED = "SET_IS_SOLVED";
exports.SET_IS_SOLVED = SET_IS_SOLVED;