"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _help_initial_state = require("../initial_states/help_initial_state");

var types = _interopRequireWildcard(require("../action_types"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var help_reducer = function help_reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _help_initial_state.helpInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.RESET_HELP_STATE:
      return _objectSpread({}, _help_initial_state.helpInitialState);

    case types.SET_SOLUTION:
      return _objectSpread({}, state, {
        solution: action.payload.solution
      });

    case types.SET_INVALID_CELLS:
      return _objectSpread({}, state, {
        invalidCells: action.payload.invalidCells
      });

    case types.SET_SHOW_HELP:
      return _objectSpread({}, state, {
        showHelp: action.payload.showHelp
      });

    case types.SET_HELP_USAGE:
      return _objectSpread({}, state, {
        helpUsage: action.payload.helpUsage
      });

    case types.SET_ON_THE_GO_VALIDATION:
      return _objectSpread({}, state, {
        onTheGoValidation: action.payload.onTheGoValidation
      });

    case types.SET_SHOW_CONNECTED_CELLS:
      return _objectSpread({}, state, {
        showConnectedCells: action.payload.showConnectedCells
      });

    case types.SET_PLACE_ALL_OF_ACTIVE:
      return _objectSpread({}, state, {
        placeAllOfActive: action.payload.placeAllOfActive
      });

    case types.SET_HINTED_CELLS:
      return _objectSpread({}, state, {
        hintedCells: action.payload.hintedCells
      });

    default:
      return state;
  }
};

var _default = help_reducer;
exports["default"] = _default;