"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = void 0;

var _board_dispatches = require("./board_dispatches");

var _timer_dispatches = require("./timer_dispatches");

var _help_dispatches = require("./help_dispatches");

var SudokuUtils = _interopRequireWildcard(require("../../utils/sudoku_utils"));

var _sudoku = _interopRequireDefault(require("../../models/sudoku"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

var useActions = function useActions(state, dispatch) {
  var boardDispatches = (0, _board_dispatches.useBoardDispatches)(dispatch);
  var helpDispatches = (0, _help_dispatches.useHelpDispatches)(dispatch);
  var timerDispatches = (0, _timer_dispatches.useTimerDispatches)(dispatch);

  var generateSudoku = function generateSudoku(difficulty) {
    timerDispatches.resetTimerState();
    helpDispatches.resetHelpState(); //animateDispatches.resetAnimate();

    boardDispatches.resetState();
    boardDispatches.setDifficulty(difficulty);

    var _SudokuUtils$generate = SudokuUtils.generateSudoku(difficulty),
        sudoku = _SudokuUtils$generate.sudoku,
        solution = _SudokuUtils$generate.solution;

    var initials = [];

    for (var i = 0; i < sudoku.board.length; ++i) {
      if (sudoku.get(i) !== 0) {
        initials.push({
          cell: i,
          number: sudoku.get(i)
        });
      }
    }

    boardDispatches.setInitialized(true);
    boardDispatches.setBoard(new _sudoku["default"](initials));
    var solutionInitials = [];

    for (var _i = 0; _i < solution.board.length; ++_i) {
      solutionInitials.push({
        cell: _i,
        number: solution.get(_i)
      });
    }

    helpDispatches.setSolution(new _sudoku["default"](solutionInitials));
    timerDispatches.setTimerActive(true);
  };

  var clearBoard = function clearBoard() {
    var board = state.board.board;
    ;
    var newBoard = board.copy();
    newBoard.clear();
    boardDispatches.setBoard(newBoard);
  };

  var setCurrentCell = function setCurrentCell(currentCell) {
    boardDispatches.setCurrentCell(currentCell);
  };

  var handleNumberClick = function handleNumberClick(number) {
    var cell = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : state.board.currentCell;
    var _state$board = state.board,
        isSolved = _state$board.isSolved,
        board = _state$board.board,
        writeCandidates = _state$board.writeCandidates;
    var placeAllOfActive = state.help.placeAllOfActive;
    if (isSolved) return;

    if (placeAllOfActive) {
      placeAllOf(number);
      togglePlaceAllOfActive();
    } else if (!board.isImmutableCell(cell)) {
      if (writeCandidates) {
        toggleCandidate(number, cell);
      } else {
        setNumber(number, cell);
      }
    }
  }; // Find usages to switch to onNumberClicked


  var setNumber = function setNumber(number, cell) {
    var board = state.board.board;
    var invalidCells = state.help.invalidCells;
    var newBoard = board.copy();
    newBoard.set(cell, number);
    var newInvalidCells = invalidCells.filter(function (invalidCell) {
      return invalidCell !== cell;
    });
    boardDispatches.setBoard(newBoard);
    helpDispatches.setInvalidCells(newInvalidCells);
    if (sudokuShouldBeValidated(newBoard)) validateSudoku(newBoard);
  };

  var sudokuShouldBeValidated = function sudokuShouldBeValidated(sudoku) {
    return sudoku.isFull() || state.help.onTheGoValidation;
  };

  var removeNumberFromCell = function removeNumberFromCell(cell) {
    handleNumberClick(0, cell);
  };

  var validateSudoku = function validateSudoku() {
    var board = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : state.board.board;
    var invalidCells = SudokuUtils.validate_sudoku(board);
    helpDispatches.setInvalidCells(invalidCells);

    if (invalidCells.length === 0 && board.isFull()) {
      timerDispatches.setTimerActive(false);
      boardDispatches.setIsSolved(true);
      handleSolved();
    } else {
      addHelpUsage(HELP_TYPES.VALIDATION);
    }
  };

  var toggleCandidate = function toggleCandidate(number, cell) {
    var candidates = state.board.candidates;
    var newCandidates = new Map(candidates);
    var cellCandidates = newCandidates.get(cell);

    if (cellCandidates) {
      if (cellCandidates.includes(number)) {
        cellCandidates = cellCandidates.filter(function (candidate) {
          return candidate !== number;
        });
      } else {
        cellCandidates.push(number);
      }

      newCandidates.set(cell, cellCandidates);
    } else {
      newCandidates.set(cell, [number]);
    }

    boardDispatches.setCandidates(newCandidates);
  };

  var toggleWriteCandidates = function toggleWriteCandidates() {
    var writeCandidates = state.board.writeCandidates;
    var newWriteCandidates = !writeCandidates;
    boardDispatches.setWriteCandidates(newWriteCandidates);
  };

  var solveSudoku = function solveSudoku() {
    var CELLS_IN_SUDOKU = 81;
    var _state$help = state.help,
        solution = _state$help.solution,
        hintedCells = _state$help.hintedCells;
    var board = state.board.board;
    var newHintedCells = hintedCells.slice();
    var newBoard = board.copy();
    var nbrOfHinted = 0;

    for (var cell = 0; cell < CELLS_IN_SUDOKU; ++cell) {
      if (!board.isImmutableCell(cell) && board.get(cell) !== solution.get(cell)) {
        newBoard.addInitial(cell, solution.get(cell));
        newHintedCells.push(cell);
        nbrOfHinted++;
      }
    }

    addHelpUsage(HELP_TYPES.HINT, nbrOfHinted);
    boardDispatches.setBoard(newBoard);
    timerDispatches.setTimerActive(false);
    helpDispatches.setHintedCells(newHintedCells);
    handleSolved();
  };

  var placeAllOf = function placeAllOf(number) {
    var board = state.board.board;
    var _state$help2 = state.help,
        solution = _state$help2.solution,
        hintedCells = _state$help2.hintedCells;
    var newBoard = board.copy();
    var newHintedCells = hintedCells.slice();
    var alreadyFilled = board.immutable.map(function (immutableCell) {
      if (immutableCell.number === number) return immutableCell.cell;
    });
    var nbrOfHints = 0;
    solution.board.forEach(function (numberInCell, cell) {
      if (numberInCell === number && !alreadyFilled.includes(cell)) {
        newBoard.addInitial(cell, number);
        newHintedCells.push(cell);
        nbrOfHints++;
      }
    });
    addHelpUsage(HELP_TYPES.HINT, nbrOfHints);
    boardDispatches.setBoard(newBoard);
    helpDispatches.setHintedCells(newHintedCells);
    if (sudokuShouldBeValidated(newBoard)) validateSudoku(newBoard);
  };

  var addHint = function addHint() {
    var cell = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : state.board.currentCell;
    var board = state.board.board;
    var _state$help3 = state.help,
        hintedCells = _state$help3.hintedCells,
        solution = _state$help3.solution;
    var newBoard = board.copy();
    var newHintedCells = hintedCells.slice();
    var numberInCell = solution.get(cell);

    if (!newBoard.isImmutableCell(cell)) {
      newBoard.addInitial(cell, numberInCell);
      newHintedCells.push(cell);
      boardDispatches.setBoard(newBoard);
      addHelpUsage(HELP_TYPES.HINT);
      helpDispatches.setHintedCells(newHintedCells);
      if (sudokuShouldBeValidated(newBoard)) validateSudoku(newBoard);
    }
  };

  var toggleShowHelp = function toggleShowHelp() {
    var showHelp = state.help.showHelp;
    var newShowHelp = !showHelp;
    helpDispatches.setShowHelp(newShowHelp);
  };

  var toggleOnTheGoValidation = function toggleOnTheGoValidation() {
    var onTheGoValidation = state.help.onTheGoValidation;
    var newOnTheGoValidation = !onTheGoValidation;
    helpDispatches.setOnTheGoValidation(newOnTheGoValidation);
  };

  var toggleShowConnectedCells = function toggleShowConnectedCells() {
    var showConnectedCells = state.help.showConnectedCells;
    var newShowConnectedCells = !showConnectedCells;
    helpDispatches.setShowConnectedCells(newShowConnectedCells);
  };

  var togglePlaceAllOfActive = function togglePlaceAllOfActive() {
    var placeAllOfActive = state.help.placeAllOfActive;
    var newPlaceAllOfActive = !placeAllOfActive;
    helpDispatches.setPlaceAllOfActive(newPlaceAllOfActive);
  };

  var HELP_TYPES = {
    VALIDATION: 'validation',
    HINT: 'hint',
    PLACE_ALL_OF: 'placeAllOf',
    ON_THE_GO_VALIDATION: 'onTheGoValidation'
  };

  var addHelpUsage = function addHelpUsage(helpType) {
    var nbrOfHelps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    var helpUsage = state.help.helpUsage;
    var newHelpUsage = new Map(helpUsage);
    newHelpUsage.set(helpType, newHelpUsage.get(helpType) + nbrOfHelps);
    helpDispatches.setHelpUsage(newHelpUsage);
  };

  var tickTimer = function tickTimer() {
    var timeElapsed = state.timer.timeElapsed;
    timerDispatches.setTimeElapsed(timeElapsed + 1);
  };

  var handleSolved = function handleSolved() {
    var onSolved = state.board.onSolved;
    var helpUsage = state.help.helpUsage;
    var timeElapsed = state.timer.timeElapsed;
    onSolved({
      time: timeElapsed,
      helps: helpUsage
    });
  };

  return {
    generateSudoku: generateSudoku,
    clearBoard: clearBoard,
    setCurrentCell: setCurrentCell,
    handleNumberClick: handleNumberClick,
    setNumber: setNumber,
    removeNumberFromCell: removeNumberFromCell,
    toggleWriteCandidates: toggleWriteCandidates,
    validateSudoku: validateSudoku,
    solveSudoku: solveSudoku,
    addHint: addHint,
    toggleShowHelp: toggleShowHelp,
    toggleOnTheGoValidation: toggleOnTheGoValidation,
    toggleShowConnectedCells: toggleShowConnectedCells,
    togglePlaceAllOfActive: togglePlaceAllOfActive,
    tickTimer: tickTimer
  };
};

exports.useActions = useActions;