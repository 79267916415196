"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _StoreContext = require("../../hooks/StoreContext");

require("./timer-styles.css");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

var Timer = function Timer(props) {
  var disable = props.disable;
  if (disable) return _react["default"].createElement(_react["default"].Fragment, null);

  var _useContext = (0, _react.useContext)(_StoreContext.StoreContext),
      state = _useContext.state,
      actions = _useContext.actions;

  var interv = -100;
  (0, _react.useEffect)(function () {
    interv = setInterval(tickTimer, 1000);
    return function cleanup() {
      clearInterval(interv);
    };
  });

  var tickTimer = function tickTimer() {
    if (state.timer.timerActive) actions.tickTimer();
  };

  var timeElapsed = state.timer.timeElapsed;

  var formatTime = function formatTime() {
    var time = timeElapsed;
    var h = Math.floor(time / 60 / 60);
    var m = Math.floor(time / 60) - h * 60;
    var s = time - h * 60 * 60 - m * 60;
    var hh = h < 10 ? "0".concat(h) : "".concat(h);
    var mm = m < 10 ? "0".concat(m) : "".concat(m);
    var ss = s < 10 ? "0".concat(s) : "".concat(s);
    return "".concat(hh, ":").concat(mm, ":").concat(ss);
  };

  return _react["default"].createElement("h5", {
    className: "timer-container"
  }, formatTime());
};

var _default = Timer;
exports["default"] = _default;