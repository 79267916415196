import React, { Component } from 'react';
import { Navbar, Container, NavbarBrand } from 'react-bootstrap'; 
import './App.css'; 

class Footer extends Component{
  render() {
      return(
          <div className="fixed-bottom" style={{textAlign:"center"}}>  
              <Navbar bg="dark" variant="dark">
                  <Container>
                      <NavbarBrand className="navbar-brand-custom">visit <a href="http://www.hightechguru.net" target="_blank" rel="noopener noreferrer">guru's web</a> for more web apps!</NavbarBrand>
                  </Container >
              </Navbar>
          </div>
      )
  }
}

export default Footer;
