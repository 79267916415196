"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.helpInitialState = void 0;

var _sudoku = _interopRequireDefault(require("../../models/sudoku"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var helpInitialState = {
  solution: new _sudoku["default"](),
  invalidCells: [],
  showHelp: false,
  helpUsage: new Map([['validation', 0], ['hint', 0]]),
  onTheGoValidation: false,
  showConnectedCells: true,
  placeAllOfActive: false,
  hintedCells: []
};
exports.helpInitialState = helpInitialState;