import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './pageHeader.js';
import Footer from './pageFooter';
import './App.css';


class App extends Component{
  render() {
    return (
      <div className="navbar-brand-custom">
        <Header />
        <div style={{ textAlign: "center" }}>
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;