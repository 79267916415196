"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoreProvider = exports.StoreContext = void 0;

var _react = _interopRequireWildcard(require("react"));

var _actions = require("./actions/actions");

var _reducers = _interopRequireDefault(require("./reducers"));

var _initial_state = _interopRequireDefault(require("./initial_state"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var StoreContext = (0, _react.createContext)(_initial_state["default"]);
exports.StoreContext = StoreContext;

var StoreProvider = function StoreProvider(_ref) {
  var children = _ref.children,
      onSolved = _ref.onSolved;
  _initial_state["default"].board.onSolved = onSolved;

  var _useReducer = (0, _react.useReducer)(_reducers["default"], _initial_state["default"]),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var actions = (0, _actions.useActions)(state, dispatch);
  return _react["default"].createElement(StoreContext.Provider, {
    value: {
      state: state,
      dispatch: dispatch,
      actions: actions
    }
  }, children);
};

exports.StoreProvider = StoreProvider;