import { RouterSharp } from '@material-ui/icons';
import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, NavItem } from 'react-bootstrap';
import { Link, Switch, Route } from 'react-router-dom';
import Demo from './sidesheet';
import Puzzle from './sudokuPuzzle';
import Home from './home';



class Header extends Component {
  render() {
    return (
      <header id="home">

        <Navbar bg="dark" variant="dark">
          <Navbar.Brand as={Link} to="/">
            <img
              alt=""
              src="favicon.ico"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
              Guru's Workshop
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">

              <NavItem eventkey={2} href="/sudoku">
                <Nav.Link as={Link} to="/sudoku" >Free Sudoku</Nav.Link>
              </NavItem>

            </Nav>

          </Navbar.Collapse>


        </Navbar>
        <div>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/sudoku' component={Puzzle} />
            <Route render={function () {
              return <p>Not found</p>
            }} />
          </Switch>
        </div>
      </header>
    );
  }
}

export default Header;

/* <NavItem eventkey={1} href="/demo">
                <Nav.Link as={Link} to="/demo" >Sidesheet demo</Nav.Link>
              </NavItem>
            <Route exact path='/demo' component={Demo} /> */
