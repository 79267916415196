"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _combineReducer = _interopRequireDefault(require("./combineReducer"));

var _board_reducer = _interopRequireDefault(require("./reducers/board_reducer"));

var _timer_reducer = _interopRequireDefault(require("./reducers/timer_reducer"));

var _help_reducer = _interopRequireDefault(require("./reducers/help_reducer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = (0, _combineReducer["default"])({
  board: _board_reducer["default"],
  timer: _timer_reducer["default"],
  help: _help_reducer["default"]
});

exports["default"] = _default;