"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timerInitialState = void 0;
var timerInitialState = {
  timerActive: false,
  timeElapsed: 0
};
exports.timerInitialState = timerInitialState;