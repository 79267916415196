"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var combineReducers = function combineReducers(reducers) {
  return function (state, action) {
    var hasChanged;
    var nextState = Object.keys(reducers).reduce(function (result, key) {
      result[key] = reducers[key](state[key], action);
      hasChanged = hasChanged || result[key] !== state[key];
      return result;
    }, {});
    return hasChanged ? nextState : state;
  };
};

var _default = combineReducers;
exports["default"] = _default;